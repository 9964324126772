/**
 * Register resource for async load with require-bundle
 */
(function(){

    'use strict';

    // Bail if require bundle or server variables not loaded
    if ( ! RequireBundle || ! fluidthemeSettings ) return;

    var settings = fluidthemeSettings,
        verParent =fluidthemeSettings.assetsVersion,
        verChild = fluidthemeSettings.assetsVersionChild;

    // Theme Style bundles
    RequireBundle.deregister( 'post-styles' );
    RequireBundle.deregister( 'navigation-styles' );
    RequireBundle.deregister( 'comment-styles' );
    RequireBundle.deregister( 'block-yoast-faq' );

    RequireBundle.register( 'post-styles', [ settings.cssPathChild + 'post' + verChild + '.min.css' ], '.blog-posts__list,.post-excerpt,.single-post' );
    RequireBundle.register( 'navigation-styles', [ settings.cssPathChild + 'navigation' + verChild + '.min.css' ], '.pagination,.woocommerce-pagination,.nav-links,.page-links' );
    RequireBundle.register( 'comment-styles', [ settings.cssPathChild + 'comment' + verChild + '.min.css' ], '#comments' );
    RequireBundle.register( 'block-yoast-faq', [ settings.cssPathChild + 'block-yoast-faq' + verChild + '.min.css' ], '.schema-faq-section' );
    
    RequireBundle.register( 'tattoobon-find-shop-suggestions', [ settings.jsPathChild + 'find-shop-suggestions' + verChild + '.min.js' ], '[data-find-shop-suggestion]', function(){ FindShopSuggestions.init(); } );
    RequireBundle.register( 'tattoobon-buy-voucher-switcher', [ settings.jsPathChild + 'buy-voucher-switcher' + verChild + '.min.js' ], '.buy-voucher__product-image[data-position]', function(){ BuyVoucherSwitcher.init(); } );

})();
